'use client'

import { useTranslations } from 'next-intl'
import { Button } from 'flowbite-react'
import { unstable_noStore as noStore } from 'next/cache'
import { Refresh } from 'flowbite-react-icons/outline'
import { Gutter } from '@/components/Gutter'

interface ErrorProps {
  reset(): void
}

export default function Error({ reset }: Readonly<ErrorProps>) {
  noStore()
  const t = useTranslations('Error')

  return (
    <Gutter className="flex grow flex-col justify-center">
      <div className="mb-0 flex flex-col items-center space-y-6 text-center md:mb-0">
        <h2 className="text-4xl font-bold text-gray lg:text-6xl lg:leading-tight lg:tracking-wide">
          {t('title')}
        </h2>
        <p className="max-w-[48rem] font-serif text-xl italic leading-normal text-gray-500">
          {t('description')}
        </p>
      </div>
      <div className="mt-6 w-full text-center md:mt-10">
        <Button onClick={() => reset()} size="xl" className="mx-2 inline-flex">
          {t('reload')}
          <Refresh className="-mr-1 ml-1.5 size-6" />
        </Button>
      </div>
    </Gutter>
  )
}
