import styles from './styles.module.css'
import { cn } from '@/lib/utils'

export const Gutter = ({
  children,
  className,
  tainted,
  blockID,
}: {
  children: React.ReactNode
  className?: string
  tainted?: boolean | null
  blockID?: string | null
}) => (
  <section
    id={blockID ?? undefined}
    className={cn(
      'gutter',
      styles['gutter'],
      tainted && styles['tainted-gutter'],
      className,
    )}
  >
    <div className="container">{children}</div>
  </section>
)
